import React, { useState, useEffect, useCallback } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useParams,
} from "react-router-dom";
import Onboarding from "./components/Onboarding";
import Profile from "./components/Profile";
import LoadingProfile from "./components/LoadingProfile";
import Landing from "./components/Landing";
import PrivacyPolicy from "./components/PrivacyPolicy";
import Support from "./components/Support";
import { BASE_URL } from "./config";
import demoUser from "./user_usain.json";
import "./RootVariables.scss";
// import userObject from "./user_object.json";

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/:username" element={<ProfileWrapper />} />

        <Route path="/onboarding" element={<Onboarding />} />
        <Route path="/" element={<Landing />} />
        <Route path="/404" element={<div>404 Not Found</div>} />
        <Route
          path="/authorize"
          element={<div>Redirecting to Strava...</div>}
        />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/support" element={<Support />} />
        <Route path="/profile" element={<Profile userData={demoUser} />} />
        <Route path="/loading" element={<LoadingProfile />} />
      </Routes>
    </Router>
  );
};

const ProfileWrapper = () => {
  const [userData, setUserData] = useState(null);
  const [isCurrentUser, setIsCurrentUser] = useState(false);
  const { username } = useParams();

  const fetchUser = useCallback(async () => {
    if (!username) return;
    try {
      const response = await fetch(`${BASE_URL}/user/${username}`);
      if (!response.ok) throw new Error("Network response was not ok");
      const fetchedUserData = await response.json();
      setUserData(fetchedUserData);
      // Implement logic to determine if it's the current user
      // setIsCurrentUser(/* ... */);
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  }, [username]);

  useEffect(() => {
    fetchUser();
  }, [fetchUser]);

  if (!userData) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      {/* Commented out JSON display
      <h2>User Data:</h2>
      <pre>{JSON.stringify(userData, null, 2)}</pre>
      */}

      {/* <Profile userData={userObject} isCurrentUser={isCurrentUser} /> */}

      <Profile userData={userData} isCurrentUser={isCurrentUser} />
    </div>
  );
};

export default App;

// "v02_max": {
//   "fields_used": [
//     "distance",
//     "sex",
//     "max_heartrate",
//     "moving_time",
//     "has_heartrate",
//     "type",
//     "age"
//   ],
//   "model_name": "cooper_and_hr_avg",
//   "model_version": "strava_1.1.0",
//   "number_of_activities": 45,
//   "precision": 0.725,
//   "timestamp": "2024-09-11T19:27:37.991105",
//   "value": 53.9
// }
